// https://phosphoricons.com
import { Icon, IconProps } from '@chakra-ui/react';

interface MapIconProps extends IconProps {
  color?: string;
  size?: string;
}

export const CloseIcon = ({ color, size = '4', ...rest }: MapIconProps) => (
  // NOTE: This is actually copy pasted from Chakra-icons. Designs are missing it.
  <Icon viewBox="0 0 24 24" h={size} w={size} {...rest} color={color}>
    <path
      fill="currentColor"
      d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"
    />
  </Icon>
);

const QuoteIcon = ({ color, size = '11', ...rest }: MapIconProps) => (
  <Icon viewBox="0 0 11 11" h={size} w={size} {...rest} color={color}>
    <path
      d="m6.46 10.6v-4.28l2.12-5.760002h2.36l-1.8 5.360002h1.8v4.68zm-5.8 0v-4.28l2.16-5.760002h2.32l-1.8 5.360002h1.8v4.68z"
      fill="currentColor"
    />
  </Icon>
);

export { List as MenuIcon } from '@phosphor-icons/react';

export { QuoteIcon };

export default CloseIcon;
